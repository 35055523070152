import React, { Component } from "react";
import NewLine from "./newLine";
import Ls from "./ls";
import Cat from "./cat";
// import Help from "./help";
import 'animate.css';

// LIGHTBOX
import Lightbox from 'lightbox-react';
import '../lightbox.css';

// Date Format
import dateFormat from "dateformat";
const now = new Date(); 

const lb1 = [
  './figma/dao/style-tile.png',
  './figma/dao/logo-text.png',
  './figma/dao/logo-ukraine.png',
  './figma/dao/screen1.png',
  './figma/dao/screen2.png',
  './figma/dao/screen3.png',
  './figma/dao/screen4.png',
  './figma/dao/screen5.png',
  './figma/dao/screen6.png',
];


export class Page extends Component {
  state = {
    numberOfLine: 0,
    displayEverything: [{ value: "", id: 0, displayInput: true, type: "line" }],

    // LIGHTBOX PROPS
    photoIndex: 0,
    isOpen: false
  };
  information = {
    name: `0xjdavis`,
    help: [
      {
        id: 1,
        type: "cat"
      },
      {
        id: 2,
        type: "clear"
      },
      {
        id: 3,
        type: "cmd"
      },
      {
        id: 4,
        type: "ls"
      },
    ],
    commands: [
      {
        id: 5,
        type: "cat"
      },
      {
        id: 6,
        type: "clear"
      },
      {
        id: 7,
        type: "cmd"
      },/*
        id: 8,
        type: "help"
      },*/
      {
        id: 9,
        type: "ls"
      },
    ],
    subDir: [
      {
        id: 10,
        type: "about.html"
      },
      {
        id: 11,
        type: "artwork.html"
      },
      {
        id: 12,
        type: "contact.html"
      },
      {
        id: 13,
        type: "education.html"
      },
      {
        id: 14,
        type: "projects.html"
      },
      {
        id: 15,
        type: "socials.html"
      },
      {
        id: 16,
        type: "why.html"
      }
    ]
  };


  render() {
    const { photoIndex, isOpen } = this.state; // lightbox

    return (
      <div className="wrapper">
        <div className="mobileAlert">
          <div>
            <pre>  █████           █████████████            █████    </pre>
            <pre>(███████       █████████████████████      ████████  </pre>
            <pre>██████████. █████████████████████████ ████████████ </pre>
            <pre>  ,    ███ ████████████████████████████#,███   ..   </pre>
            <pre>          █████████████████████████████             </pre>
            <pre>          ██████████████████████████████%           </pre>
            <pre>          █████████████████████████████/           </pre>
            <pre>          █████████████████████████████             </pre>
            <pre>          ████       ███████       ████             </pre>
            <pre>          ███    @    ████&   @     ███             </pre>
            <pre>          █████     (██   ██,     █████             </pre>
            <pre>  %(   ████ ███████████   ████████████& ████   %(   </pre>
            <pre>███████████,      %████████████*      ████████████ </pre>
            <pre>███████&         *█████████████          ████████   </pre>
            <pre>  █████             █████████            ██████     </pre>
            <h3 className="pink">Device Alert!</h3>
            <p className="text">
              It appears you may be on a mobile device and the command line interface can be
              challenging to use on mobile devices. Therefore, you are getting the bare-bones
              experience with only my <strong>Web3</strong> projects listed. 
            </p>

            <button className="continue" onClick={() => this.closeAlert()} >
              <p>Continue</p>
            </button>
          </div>
        </div>
        <div className="terminal">
          <p className="text datetime" >
            Last login: {dateFormat(now, 'ddd mmm dd HH:MM:ss')} jdavis.xyz
          </p>
          <br />
          <div id="hero">

            <pre className="animate__animated animate__fadeInDown"> █████╗ ██╗         </pre>
            <pre className="animate__animated animate__fadeInDown">██╔══██╗██║         </pre>
            <pre className="animate__animated animate__fadeInDown">███████║██║         </pre>
            <pre className="animate__animated animate__fadeInDown">██╔══██║██║         </pre>
            <pre className="animate__animated animate__fadeInDown">██║  ██║██║         </pre>
            <pre className="animate__animated animate__fadeInDown">╚═╝  ╚═╝╚═╝         </pre>            
            <pre>                                  </pre>
            <pre className="animate__animated animate__fadeInDown">██████╗  █████╗  ██████╗          </pre>
            <pre className="animate__animated animate__fadeInDown">██╔══██╗██╔══██╗██╔═══██╗         </pre>
            <pre className="animate__animated animate__fadeInDown">██║  ██║███████║██║   ██║         </pre>
            <pre className="animate__animated animate__fadeInDown">██║  ██║██╔══██║██║   ██║         </pre>
            <pre className="animate__animated animate__fadeInDown">██████╔╝██║  ██║╚██████╔╝         </pre>
            <pre className="animate__animated animate__fadeInDown">╚═════╝ ╚═╝  ╚═╝ ╚═════╝          </pre>
            <pre>                                  </pre>
            <pre className="animate__animated animate__fadeIn">███╗   ██╗███████╗████████╗       </pre>
            <pre className="animate__animated animate__fadeIn">████╗  ██║██╔════╝╚══██╔══╝       </pre>
            <pre className="animate__animated animate__fadeIn">██╔██╗ ██║█████╗     ██║          </pre>
            <pre className="animate__animated animate__fadeIn">██║╚██╗██║██╔══╝     ██║          </pre>
            <pre className="animate__animated animate__fadeIn">██║ ╚████║██║        ██║          </pre>
            <pre className="animate__animated animate__fadeIn">╚═╝  ╚═══╝╚═╝        ╚═╝          </pre>
            <pre>                                  </pre>
            <pre className="animate__animated animate__fadeInUp">██╗    ██╗███████╗██████╗ ██████╗ </pre>
            <pre className="animate__animated animate__fadeInUp">██║    ██║██╔════╝██╔══██╗╚════██╗</pre>
            <pre className="animate__animated animate__fadeInUp">██║ █╗ ██║█████╗  ██████╔╝ █████╔╝</pre>
            <pre className="animate__animated animate__fadeInUp">██║███╗██║██╔══╝  ██╔══██╗ ╚═══██╗</pre>
            <pre className="animate__animated animate__fadeInUp">╚███╔███╔╝███████╗██████╔╝██████╔╝</pre>
            <pre className="animate__animated animate__fadeInUp"> ╚══╝╚══╝ ╚══════╝╚═════╝ ╚═════╝</pre>
          </div>
          <br />
          <br />

          <p id="p1" className="text fadeIn">
            Welcome!<br /><br />
            <span className="magritte">
              <em>Ceci n'est pas une terminal.</em><br /><br />
            </span>
            This is <span className="hide">the treachery of technology,</span> a product design portfolio for <a href="https://twitter.com/0xjdavis" target="_blank" rel="noopener noreferrer">0xjdavis</a> which consists
            of some <strong>AI art</strong>, <strong>DAO</strong>, generative <strong>NFT</strong>, and <strong>Web3</strong> projects.
          </p>

          <p id="p2" className="text fadeIn1">
            <a className="orange"
              href="https://metamask.io/download/" 
              target="_blank" 
              rel="noopener noreferrer"
            ><span role="img" aria-label="fox">🦊</span> MetaMask</a> is required in all Web3 projects for authentication, signing, and payments.
          </p>

          <p id="p3" className="text fadeIn2">
            For a list of commands, type <span className="blue">cmd</span>.<br />
            {/*For help type "help" + "cmd".<br />*/}
            Type <span className="blue">cat</span> + file name to view files.
          </p>

          <div id="p4">
          {// eslint-disable-next-line
          this.state.displayEverything.map(l => {
            if (l.type === "line") {
              return (
                <NewLine
                  className="here"
                  key={l.id}
                  handleInput={this.handleInput}
                  line={l}
                  displayInput={l.displayInput}
                ></NewLine>
              );
            } else if (l.type === "ls") {
              return (
                <Ls key={l.id} line={l} subDir={this.information.subDir}></Ls>
              );
            } else if (l.type === "cmd") {
              return (
                <Ls key={l.id} line={l} subDir={this.information.commands}></Ls>
              );
            } /* else if (l.type === "help") {
              return (
                <Help key={l.id} line={l} subDir={this.information.commands}></Help>
              );
            } */ else if (l.type === "cat") {
              return (
                <Cat key={l.id} line={l} subDir={this.information.subDir}></Cat>
              );
            } else {
              // do nothing
            }
          })}
          </div>

          <div id="p5">
            <div className="container">
              {/* 
              <div className="result">
                <div className="project fadeIn2">
                  <a alt="AI Resume Insights"
                    title="AI Resume Insights"
                    href="https://resume-insights.jdavis.xyz"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="AI Resume Insights" src="/th_gpt3_ai_resume_insights.png" />
                    <p> </p>
                    View Project
                  </a>
                </div>
              </div>

              <div className="result">
                <div className="project fadeIn2">
                  <a alt="AI Generated Cover Letter"
                    title="AI Generated Cover Letter"
                    href="https://cover-letter.jdavis.xyz/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="AI Generated Cover Letter" src="/th_gpt3_ai_cover_letter.png" />
                    <p> </p>
                    View Project
                  </a>
                </div>
              </div>
              */}
              
              <div className="result">
                <div className="project fadeIn2">
                  <a alt="Gen AI Prototypes"
                    title="Gen AI Prototypes"
                    href="https://ai.jdavis.xyz/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="Gen AI Prototypes" src="/th_sorcery_ai.png" />
                    <p>{/* details */}</p>
                    View ReadMe
                  </a>
                </div>
              </div>

              <div className="result">
                <div className="project fadeIn2">
                  <a alt="UX UI VUI Case Studies"
                    title="UX UI VUI Case Studies"
                    href="https://pitch.com/v/ux-ui-vui-portfolio-jeff-davis-4b69fu"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="UX UI VUI Case Studies" src="/th_ux_ui_vui.png" />
                    <p>{/* details */}</p>
                    View Case Studies
                  </a>
                </div>
              </div>

              <div className="result">
                <div className="project fadeIn2">
                  <a alt="Gen AI Job Tools"
                    title="Gen AI Job Tools"
                    href="https://job-tools.jdavis.xyz/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="Gen AI Job Tools" src="/th_job_tools.png" />
                    <p>{/* details */}</p>
                    View Project
                  </a>
                </div>
              </div>

              <div className="result">
                <div className="project fadeIn3">
                  <a alt="DɅO DɅO"
                    title="DɅO DɅO"
                    href="https://daodao.surge.sh"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="DAO DAO" src="/th_daodao.png" />
                    <p>{/* details */}</p>
                    View Project
                  </a>

                  <div className="lightbox-trigger">
                    <button className="notabutton" type="button" onClick={() => this.setState({ isOpen: true })}>
                      <img className="figma" src="./figma.svg" alt="Figma" title="View Figma Files" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="result">
                <div className="project fadeIn4">
                  <a alt="L2 Domains"
                    title="L2 Domains"
                    href="https://l2domains.surge.sh"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="DAO DAO" src="/th_l2.png" />
                    <p>{/* details */}</p>
                    View Project
                  </a>
                </div>
              </div>

              <div className="result">
                <div className="project fadeIn5">
                  <a alt="🍒🍒🍒"
                    title="🍒🍒🍒"
                    href="https://cherrycherrycherry.surge.sh"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img alt="DAO DAO" src="/th_cherry.png" />
                    <p>{/* details */}</p>
                    View Project
                  </a>
                </div>
              </div>

            </div>

            <div className="delay5 scroll-tip green">
              <br />
              <img alt="arrow" src="/green-arrow.gif" /> Swipe left to view more projects.
            </div>
          </div>
        </div>

        {isOpen ? (
          <Lightbox
            mainSrc={lb1[photoIndex]}
            nextSrc={lb1[(photoIndex + 1) % lb1.length]}
            prevSrc={lb1[(photoIndex + lb1.length - 1) % lb1.length]}
            onCloseRequest={() => this.setState({isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + lb1.length - 1) % lb1.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % lb1.length,
              })
            }
          />
        ) : (
          null
        )}

        {/* <div className="bottom"></div> */}
      </div>
    );
  }



  // TYPE TEXT - WIP
  /*
  typeWriter = () =>{
 
    let i = 0;
    let date= dateFormat(now, 'ddd mmm dd HH:MM:ss');
    let txt = "Last login: " + date + " jdavis.xyz";
    let speed = 50;
    // console.log(now, txt);

    // document.querySelector(".datetime").innerHTML="hello";
  
    if (i < txt.length) {
      document.querySelector(".datetime").innerHTML += txt.charAt(i);
        // let target = document.getElementById("hero")
      txt.charAt(i);
        // target.innerHTML.carAt(i);
      i++;
      setTimeout(
        typeWriter, 
        speed
      );
    }
  }
  */


  handleInput = (string_value, Tid) => {
    let result = string_value.toLowerCase();
    let res = result.split(" ");

    if (res[0] === "clear") {
      // CLEAR
      this.setState({
        numberOfLine: 0,
        displayEverything: [
          { value: "", id: 0, displayInput: true, type: "line" }
        ]
      });
    } else if (res[0] === "telephone" || res[0] === "tel" || res[0] === "phone" || res[0] === "cell" ) {
      // TELEPHONE
      this.setState({
        numberOfLine: 0,
        displayEverything: [
          { value: "Call 0xjdavis", id: 0, displayInput: true, type: "line" }
        ]
      });
    } else if (res[0] === "Call 0xjdavis") {
      // CALL ME
      window.open(
        'tel:+15305808420',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "bitcoin" || res[0] === "bitcorn" || res[0] === "btc" || res[0] === "satoshi" ) {
      // BITCOIN
      this.setState({
        numberOfLine: 0,
        displayEverything: [
          { value: "🚀", id: 0, displayInput: true, type: "line" }
        ]
      });
    } else if (res[0] === "ethereum" || res[0] === "eth" || res[0] === "address" || res[0] === "ens" ) {
      // ENS
      this.setState({
        numberOfLine: 0,
        displayEverything: [ // SET UP SEND MONEY
          { value: "jdavis.eth", id: 0, displayInput: true, type: "line" }
        ]
      });
    } else if (res[0] === "jdavis.eth") {
      // SEND MONEY
      window.open(
        'https://etherscan.io/enslookup-search?search=jdavis.eth',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "opensea") {
      // OPENSEA
      window.open(
        'https://opensea.io/0xjdavis',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "metamask") {
      // METAMASK
      window.open(
        'https://metamask.io/download/',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "dribbble") {
      // DRIBBBLE
      window.open(
        'https://dribbble.com/0xjdavis',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "github") {
      // GITHUB
      window.open(
        'https://github.com/0xjdavis',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "twitter") {
      // TWITTER
      window.open(
        'https://twitter.com/0xjdavis',
        '_blank'
      );
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    } else if (res[0] === "exit") {
      // EXIT
      window.location.href = 'https://duckduckgo.com';
    } else if (res[0] === "shots") { // VIEW SHOTS
      // VIEW SHOTS
      window.open(
        'https://shots.jdavis.xyz',
        '_blank'
      );
    } else if (res[0] === "pdf" || res[0] === "portfolio" || res[0] === "download") { // DOWNLOAD PDF
      // DOWNLOAD PORTFOLIO
      window.open(
        'https://jdavis.xyz/portfolio-jdavis.pdf',
        '_blank'
      );
    } else if (res[0] === "easter" || res[0] === "eggs" || res[0] === "egg" ) {
      // EASTER EGG
      this.setState({
        numberOfLine: 0,
        displayEverything: [
          { value: "🐇🥚", id: 0, displayInput: true, type: "line" }
        ]
      });
    } else {
      // HANDLE ANYTHING ELSE
      this.setState(
        {
          displayEverything: [
            ...this.state.displayEverything.filter(l => l.id !== Tid),
            //...{ value: string_value, id: Tid, displayInput: false , type: "line"}
            {
              ...this.state.displayEverything.find(l => l.id === Tid),
              value: string_value,
              type: res[0]
            }
          ]
        },
        () => this.handleClick()
      );
    }
    /*
    function mobileAlert() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        console.log("Agent" + this.navigator.userAgent);
        document.querySelector(".mobileAlert").style.display="block"
      } else {
        console.log("User Agent is not mobile");
      }
    }
    mobileAlert();
    */
  };

  closeAlert = () => {
    document.querySelector(".mobileAlert").classList.add('fadeOut')
    setTimeout(() => {
      document.querySelector(".mobileAlert").style.display="none"
    }, 750)
  }

  // Adding a new line after a click
  handleClick = event => {
    const num = this.state.displayEverything.length + 1;
    this.setState({
      numberOfLine: this.state.numberOfLine + 1,
      displayEverything: [
        ...this.state.displayEverything,
        { value: "", id: num, displayInput: true, type: "line" }
      ]
    })
  }
}

export default Page;
