import React, { Component } from "react";

// LIGHTBOX
import Lightbox from 'lightbox-react';
import '../lightbox.css';
// import VideoIframe from './video';

const lb1 = [ //https://morioh.com/p/3982075a907a // Ref: https://jfcaiceo.github.io/react-lightbox-component/?ref=morioh.com&utm_source=morioh.com
  /*VideoIframe,
  {
    src: 'https://media.istockphoto.com/photos/cobberdog-pup-on-white-background-picture-id1306641965?b=1&k=20&m=1306641965&s=170667a&w=0&h=Ulbyz5BZWzRfLg6uuGLNuRd8Ar3yyzl3PRr4u7o6M8c=',
    title: 'Cobberdog',
    description: 'Such a good boy!'
  },
  */
  './figma/dao/style-tile.png',
  './figma/dao/logo-text.png',
  './figma/dao/logo-ukraine.png',
  './figma/dao/screen1.png',
  './figma/dao/screen2.png',
  './figma/dao/screen3.png',
  './figma/dao/screen4.png',
  './figma/dao/screen5.png',
  './figma/dao/screen6.png',
];

const lb2 = [
  './figma/ai/garden1.png',
  './figma/ai/garden2.png',
];

export class Cat extends Component {
  state = {
    type: this.props.line.type,
    value: this.props.line.value,

    // LIGHTBOX PROPS
    photoIndex: 0,
    isOpen: false,
  };


  // PRESS ENTER AND MOVE TO CURSOR - WIP
  handleKeyPress = (event) =>{
    if(event.key === 'Enter'){
      console.log('enter press here! ')
    }
  } 

  data = {
    about:
      "This is a portfolio of recent proof of concept projects by <a href=\"https://twitter.com/0xjdavis\" target=\"_blank\">0xjdavis</a>, a <strong>Web3</strong> Product Designer with a BFA and 20+ years of experience providing design leadership and <strong>UX</strong>/<strong>UI</strong>/<strong>VUI</strong> services to startups and Fortune 10 companies.",
    education:
      "Fine arts interest lead to receiving a BFA in Graphic Design from the University of Tennssee School of Art.<br /><br /> A focused interest has lead to continued studies in computer science, AI, machine learning and blockchain technologies through various Ivy League programs.<br /><br /><em>\"Perhaps it is due to my INTP personality... I don't know, but I have an insatiable curiosity to learn.\"</em> <span className=\"widow\">~ 0xjdavis</span>",
    projects: [
      {
        projectName: "AI PROTOTYPES",
        projectThumbnail: "th_sorcery_ai.png",
        projectDetails: "",
        liveDemo: "https://ai.jdavis.xyz",
        linkToGithub: "https://github.com/0xjdavis"
      },      
      {
        projectName: "UX UI VUI PORTFOLIO",
        projectThumbnail: "th_ux_ui_vui.png",
        projectDetails: "",
        liveDemo: "https://pitch.com/v/ux-ui-vui-portfolio-jeff-davis-4b69fu",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        projectName: "GEN AI JOB TOOLS",
        projectThumbnail: "th_job_tools.png",
        projectDetails: "",
        liveDemo: "https://job-tools.jdavis.xyz",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        projectName: "DɅO DɅO",
        projectThumbnail: "th_daodao.png",
        projectDetails: "",
        liveDemo: "https://daodao.surge.sh",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        projectName: "L2 Domains",
        projectThumbnail: "th_l2.png",
        projectDetails: "",
        liveDemo: "https://l2domains.surge.sh",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        projectName: "🍒 🍒 🍒",
        projectThumbnail: "th_cherry.png",
        projectDetails: "",
        liveDemo: "https://cherrycherrycherry.surge.sh",
        linkToGithub: "https://github.com/0xjdavis"
      }
    ],
    artwork: 
      "View my AI art, generative art, and select design projects at <a href=\"https://dribbble.com/0xjdavis\" target=\"_blank\">Dribbble</a> or <a href=\"https://shots.jdavis.xyz\" target=\"_blank\">shots.jdavis.xyz</a>",
    /*[
      {
        artworkName: "AI ART",
        artworkThumbnail: "th_painting1.png",
        artworkDetails: "",
        liveDemo: "https://cdn.midjourney.com/ffbd85c3-5c4c-4877-a33f-668e7c6a82f7/grid_0.png",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        artworkName: "AI ART - Fantasy Garden",
        artworkThumbnail: "th_garden6.png",
        artworkDetails: "",
        liveDemo: "./ai-art/garden6.png",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        artworkName: "AI ART - Fantasy Garden",
        artworkThumbnail: "th_garden5.png",
        artworkDetails: "",
        liveDemo: "./ai-art/garden5.png",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        artworkName: "AI ART - Fantasy Garden",
        artworkThumbnail: "th_garden4.png",
        artworkDetails: "",
        liveDemo: "./ai-art/garden4.png",
        linkToGithub: "https://github.com/0xjdavis"
      },{
        artworkName: "AI ART - Fantasy Garden",
        artworkThumbnail: "th_garden3.png",
        artworkDetails: "",
        liveDemo: "./ai-art/garden3.png",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        artworkName: "AI ART - Fantasy Garden",
        artworkThumbnail: "th_garden2.png",
        artworkDetails: "",
        liveDemo: "./ai-art/garden2.png",
        linkToGithub: "https://github.com/0xjdavis"
      },
      {
        artworkName: "AI ART - Fantasy Garden",
        artworkThumbnail: "th_garden1.png",
        artworkDetails: "",
        liveDemo: "./ai-art/garden1.png",
        linkToGithub: "https://github.com/0xjdavis"
      }
    ],*/
    resumes: [
      {
        resumeName: "Resume for J. Davis",
        resumeThumbnail: "th_resume.png",
        resumeDetails: "",
        resumeLink: "https://docs.google.com/document/d/1_gKOCmZjtSMOb0DkZJo8RpoDIPanloEbHiOPIkr79So/edit?usp=sharing"
      }
    ],
    contact:
      "0xjdavis",
    poop:
      "<span role=\"img\" aria-label=\"clinking beer mugs\" class=\"icon\">🍻</span><br /><br />Ha ha... I like you.<br />You're funny!",
    socials: [
      {
        app: "Dribbble",
        link: "https://dribbble.com/0xjdavis",
        logo: "dribbble.png"
      },
      {
        app: "GitHub",
        link: "https://github.com/0xjdavis",
        logo: "github.png"
      },
      {
        app: "Twitter",
        link: "https://twitter.com/0xjdavis",
        logo: "twitter.png"
      }
    ],
    whys: [
      {
        whyName: "Terminal Inspiration",
        whyThumbnail: "th_terminal.png",
      }
    ],
  };


  render() {
    return (
      <div className="prompt-line" onKeyPress={this.handleKeyPress}>
        <div className="prompt-character">&gt;</div><div className="prompt" onKeyPress={this.handleKeyPress}> {this.props.line.value}</div>
        {this.handleCd()}
      </div>
    );
  }


  handleCd = () => {
    const navigation = this.state.value.split(" ")[1];
    const { photoIndex, isOpen } = this.state; // lightbox

    if (navigation) {
      const lc = navigation.toLowerCase();

      if (lc === "about.html" || lc === "about") {
        return  (
          <div className="result text" dangerouslySetInnerHTML={{__html: this.data.about}}></div>
        );
      } else if (lc === "education.html" || lc === "education" || lc === "edu") {
        return (
          <div className="result text" dangerouslySetInnerHTML={{__html: this.data.education}}></div>
        );
      } else if (lc === "resume.pdf" || lc === "resume" || lc === "res") {
        return (
          <React.Fragment>
            <div className="container">
            {this.data.resumes.map(everyResume => {
              return (
                <div className="result" key={everyResume.resumeName}>
                  <div className="resume fadeIn1">
                    <a alt={everyResume.resumeName}
                      title={everyResume.resumeName}
                      href={everyResume.resumeLink}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img alt={everyResume.resumeName} src={everyResume.resumeThumbnail} />
                      <p>{everyResume.resumeDetails}</p>
                      View Resume
                    </a>
                  </div>
                </div>
              );
            })}
            </div>
            <div className="prompt-line-buffer"></div> 
          </React.Fragment>
        );
      } else if (lc === "artwork.html" || lc === "artwork" || lc === "art") {
        return  (
          <div className="result text" dangerouslySetInnerHTML={{__html: this.data.artwork}}></div>
        );
        /*return (
          <React.Fragment>
            <div className="container">
            {this.data.artwork.map(everyArtwork => {
              return (
                <div className="result" key={everyArtwork.artworkName}>
                  <div className="project fadeIn1">
                    <a alt={everyArtwork.artworkName}
                      title={everyArtwork.artworkName}
                      href={everyArtwork.liveDemo}
                      target="_blank"
                      rel="noopener noreferrer"
                      onclick={() => this.setState({ isOpen: true })} >
                      <img alt={everyArtwork.artworkName} src={everyArtwork.artworkThumbnail} />
                      <p>{everyArtwork.artworkDetails}</p>
                      View Artwork
                    </a>
                  </div>
                </div>
              );
            })}
            </div>
            <div className="prompt-line-buffer"></div> 
          </React.Fragment>
        );*/
      } else if (lc === "projects.html" || lc === "projects" || lc === "project") {
        return (
          <React.Fragment>
          <div className="container">
            {this.data.projects.map(everyProject => {
              return (
                <div className="result" key={everyProject.projectName}>
                  <div className="project fadeIn1">
                    <a alt={everyProject.projectName}
                      title={everyProject.projectName}
                      href={everyProject.liveDemo}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img alt={everyProject.projectName} src={everyProject.projectThumbnail} />
                      <p>{everyProject.projectDetails}</p>
                      View Project
                    </a>

                    {everyProject.projectName === "DɅO DɅO" ? (
                      <div className="lightbox-trigger">
                        <button className="notabutton" type="button" onClick={() => this.setState({ isOpen: true })}>
                          <img className="figma" src="./figma.svg" alt="Figma" title="View Figma Files" />
                        </button>
                      </div>
                      ) : (
                        null
                      )
                    }
                    {everyProject.projectName === "AI ART" ? (
                      <div className="lightbox-trigger">
                        <button className="notabutton" type="button" onClick={() => this.setState({ isOpen: true })}>
                          <img className="figma" src="./figma.svg" alt="Figma" title="View Figma Files" />
                        </button>
                      </div>
                      ) : (
                        null
                      )
                    }
                  

                    {everyProject.projectName === "DɅO DɅO" && isOpen ? (
                      <Lightbox
                        mainSrc={lb1[photoIndex]}
                        nextSrc={lb1[(photoIndex + 1) % lb1.length]}
                        prevSrc={lb1[(photoIndex + lb1.length - 1) % lb1.length]}
                        onCloseRequest={() => this.setState({isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + lb1.length - 1) % lb1.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % lb1.length,
                          })
                        }
                      />
                    ) : (
                      null
                    )}

                    {everyProject.projectName === "AI ART" && isOpen ? (
                      <Lightbox
                        mainSrc={lb2[photoIndex]}
                        nextSrc={lb2[(photoIndex + 1) % lb2.length]}
                        prevSrc={lb2[(photoIndex + lb2.length - 1) % lb2.length]}
                        onCloseRequest={() => this.setState({isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + lb2.length - 1) % lb2.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % lb2.length,
                          })
                        }
                      />
                    ) : (
                      null
                    )}
                  </div>
                  
                </div>
              );
            })}
          </div>

          <div className="delay5 scroll-tip green">
            <img alt="arrow" src="/green-arrow.gif" /> Swipe left to view more projects.
          </div>
          </React.Fragment>
        );
      } else if (lc === "contact.html" || lc === "contact") {
        return (
          <p className="result">
            Send a direct message to <a href="https://twitter.com/0xjdavis" target="_blank" rel="noopener noreferrer">
              {this.data.contact}
            </a>.
          </p>
        );
      } else if (lc === "poop" || lc === "poo" || lc === "fart" || lc === "shit") {
        return (
          <div className="result" dangerouslySetInnerHTML={{__html: this.data.poop}}></div>
        );
      } else if (lc === "socials.html" || lc === "socials" || lc === "social") {
        return (
          <React.Fragment>
            <div className="container">
            {this.data.socials.map(everySocial => {
              return (
                <div className="result" key={everySocial.app}>
                  <div className="social fadeIn1">
                    <a href={everySocial.link} target="_blank" rel="noopener noreferrer">
                      <img className="icon" src={everySocial.logo} alt={everySocial.app} />
                    </a>
                  </div>
                </div>
              );
            })}
            </div>
          </React.Fragment>
        );
      } else if (lc === "why.html" || lc === "why" || lc === "?") {
        return (
          <React.Fragment>
            <div className="container">
              {this.data.whys.map(everyWhy => {
                return (
                  <div className="result" key={everyWhy.whyName}>
                    <div className="why">
                      <img alt={everyWhy.whyName} src={everyWhy.whyThumbnail} />
                    </div>
                  </div>
                );
              })}
            </div>
            <p className="result text">
              Haha... always a great question!
            </p>
            <p className="result text">
              The choice to showcase modern <strong>Web3</strong> projects with a portfolio
              using what could be perceived as a nostalgic throwback style of a terminal was
              made to highlight what I call "the treachery of technology", i.e. - the reversion
              of progress in order to advance.
            </p>
            <p className="result text">  
              It is meant to highlight the stark difference in user experience that command
              line tools have when compared to that of graphical interfaces in modern apps.
            </p>
            <p className="result text">
              <em>"It's an homage to my terminal which I use daily, yet still prompts me for
                input relentlessly."</em> <span className="widow">~ 0xjdavis</span>
            </p>
          </React.Fragment>
        );
      } else {
        return (
          <p className="result">Whoops... it looks like there might be a typo!</p>
        );
      }
    } else {
      return (
        <div className="result">
          <p>Oops... wrong format.</p>
          <p>Try viewing projects.html, just type "<span className="pink">cat projects</span>".</p>
        </div>
      )
    }
  }
}

function scroll() { 
  //var element = document.querySelector(".terminal");
  //var color = "#" + Math.floor(Math.random() * 7) + Math.floor(Math.random() * 7) + Math.floor(Math.random() * 7) + Math.floor(Math.random() * 7) + Math.floor(Math.random() * 7) + Math.floor(Math.random() * 7);
  //console.log("Color = " + color)
  //element.style = "border:1px " + color  + " dashed";

  var element2 = document.querySelector(".bottom");
  if( element2 != null) {
    // console.log("ELEMENT 2")
    element2.scrollIntoView();
  }
}

scroll();


setInterval(() => {
  //scroll()
}, 0);

export default Cat;
